import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="footer p-10 bg-neutral text-neutral-content">
        <div>
          <span className="footer-title">Legal</span>
          <Link to="/impressum" className="link link-hover">
            Impressum
          </Link>
          <Link to="/datenschutz" className="link link-hover">
            Datenschutzerklärung
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
