import React from "react";
import {
  FaReact,
  FaNodeJs,
  FaWordpress,
  FaHtml5,
  FaCss3,
} from "react-icons/fa";
import { SiCsharp } from "react-icons/si";
const Skills = () => {
  return (
    <div name="skills" className="w-full h-screen bg-[#0a0a0b] text-gray-300">
      {/** container */}
      <div className="max-w-[1000px] mx-auto px-20 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-pink-600">
            Skills
          </p>
          <p className="py-4">
            Technologien mit denen ich überwiegend arbeite.
          </p>
        </div>
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center py-8">
          <div className="shadow-md shadow-[#000] hover:scale-110 duration-500 p-6">
            <FaHtml5 size="100" className="mx-auto" />
            <p>HTML</p>
          </div>
          <div className="shadow-md shadow-[#000] hover:scale-110 duration-500 p-6">
            <FaCss3 size="100" className="mx-auto" />
            <p>CSS</p>
          </div>
          <div className="shadow-md shadow-[#000] hover:scale-110 duration-500 p-6">
            <FaReact size="100" className="mx-auto" />
            <p>React</p>
          </div>
          <div className="shadow-md shadow-[#000] hover:scale-110 duration-500 p-6">
            <FaNodeJs size="100" className="mx-auto" />
            <p>NodeJs</p>
          </div>
          <div className="shadow-md shadow-[#000] hover:scale-110 duration-500 p-6">
            <FaWordpress size="100" className="mx-auto" />
            <p>Wordpress</p>
          </div>
          <div className="shadow-md shadow-[#000] hover:scale-110 duration-500 p-6">
            <SiCsharp size="100" className="mx-auto" />
            <p>CSharp</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
