import React from "react";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen bg-[#0a0a0b] flex justify-center items-center p-20"
    >
      <form action="" className="flex flex-col max-w-[600px] w-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-pink-400 text-gray-300">
            Kontakt
          </p>
          <p className="text-gray-300 py-10">info@walhalla-software.de</p>
          <p className="mt-2">
            Es reicht eine kurze E-Mail mit deinen Projektvorstellungen und
            Kontaktdaten.
            <br /> Ich antworte für gewöhnlich zeitnah.
          </p>
          {/**gitform? */}
        </div>
      </form>
    </div>
  );
};

export default Contact;
