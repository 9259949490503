import React from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

const Datenschutz = () => {
  return (
    <>
      <div className="fixed h-[80px] w-full bg-[#0a0a0b]"></div>
      <div className="bg-[#0a0a0b]">
        <Link className="fixed top-4 left-4" to="/">
          <button className="text-white group border-2 px-6 py-3 flex items-center hover:bg-pink-600 hover:border-pink-600">
            Zurück zum Start{" "}
            <span className="">
              <HiArrowNarrowLeft className="ml-3" />
            </span>
          </button>
        </Link>
        <div className="w-full min-h-screen justify-center items-center pt-20">
          <div className="max-w-[1000px] w-full min-h-screen flex flex-col items-start mx-auto container p-10 md:p-20">
            <h1 className="font-bold  text-2xl md:text-6xl">
              Datenschutzerklärung
            </h1>
            <h2 className="font-bold text-4xl pt-6">1. Allgemeines</h2>
            <h3 className="font-bold text-2xl pt-4">
              1.1 Was sind personenbezogene Daten
            </h3>
            <p>
              Personenbezogene Daten sind Angaben, die die Identität des Nutzers
              offenlegen oder offenlegen können. Wir halten uns an den Grundsatz
              der Datenvermeidung. Es wird soweit wie möglich auf die Erhebung
              von personenbezogenen Daten verzichtet.
            </p>
            <h3 className="font-bold text-2xl pt-4">
              1.2 Umgang mit personenbezogenen Daten
            </h3>
            <p>
              Personenbezogene Daten dienen ausschließlich der
              Vertragsbegründung, inhaltlichen Ausgestaltung, Durchführung oder
              Abwicklung des Vertragsverhältnisses (Art. 6 I S. 1 b DSGVO).
              Darüber hinaus werden personenbezogene Daten nur verarbeitet,
              soweit wir hierzu Ihre Einwilligung erhalten haben (Art. 6 I S. 1
              a DSGVO). Sie werden grundsätzlich nicht an Dritte weitergegeben.
              Lediglich zur Vertragserfüllung werden die Daten an das mit der
              Lieferung beauftragte Versandunternehmen weitergegeben, soweit
              dies zur Lieferung bestellter Waren erforderlich ist. Zur
              Abwicklung von Zahlungen werden die hierfür erforderlichen
              Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut
              und ggf. den beauftragten und gewählten Zahlungsdienstleister
              weitergegeben. Die Verarbeitung Ihrer personenbezogenen Daten
              erfolgt ausschließlich innerhalb der EU, soweit nachfolgend nicht
              etwas Abweichendes dargelegt ist.
            </p>
            <h3 className="font-bold text-2xl pt-4">1.3 Nutzungsdaten</h3>
            <p>
              Beim Besuch der Webseite werden allgemeine technische
              Informationen erhoben. Dies sind die verwendete IP-Adresse,
              Uhrzeit, Dauer des Besuchs, Browsertyp und ggf. die
              Herkunftsseite. Diese Nutzungsdaten werden technisch bedingt in
              einem Logfile registriert und können zum Zwecke der
              Statistikauswertung dieser Webseite benutzt und gespeichert
              werden. Eine Verknüpfung dieser Nutzungsdaten mit Ihren weiteren
              personenbezogenen Daten findet nicht statt.
            </p>
            <h3 className="font-bold text-2xl pt-4">
              1.4 Dauer der Speicherung
            </h3>
            <p>
              Wir speichern Ihre personenbezogenen Daten nach der Beendigung des
              Zwecks, für welchen die Daten erhoben wurden, nur solange, wie
              dies auf Grund der gesetzlichen (insbesondere steuerrechtlichen)
              Vorschriften erforderlich ist.
            </p>
            <h2 className="font-bold text-4xl pt-6">2. Ihre Rechte</h2>
            <h3 className="font-bold text-2xl pt-4">2.1 Auskunft</h3>
            <p>
              Sie können von uns eine Auskunft darüber verlangen, ob wir
              personenbezogene Daten von Ihnen verarbeiten und soweit dies der
              Fall ist haben Sie ein Recht auf Auskunft über diese
              personenbezogenen Daten und auf die in Art. 15 DSGVO genannten
              weiteren Informationen.
            </p>
            <h3 className="font-bold text-2xl pt-4">
              2.2 Recht auf Berichtigung
            </h3>
            <p>
              Sie haben das Recht auf Berichtigung der Sie betreffenden
              unrichtigen personenbezogenen Daten und können gemäß Art. 16 DSGVO
              die Vervollständigung unvollständiger personenbezogener Daten
              verlangen.
            </p>
            <h3 className="font-bold text-2xl pt-4">2.3 Recht auf Löschung</h3>
            <p>
              Sie haben das Recht von uns zu verlangen, dass die Sie
              betreffenden personenbezogenen Daten unverzüglich gelöscht werden.
              Wir sind verpflichtet diese unverzüglich zu löschen, insbesondere
              sofern einer der folgenden Gründe zutrifft:
            </p>
            <ul>
              <li className="mt-2">
                Ihre personenbezogenen Daten sind für die Zwecke, für die sie
                erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
                notwendig.
              </li>
              <li className="mt-2">
                Sie widerrufen ihre Einwilligung, auf die sich die Verarbeitung
                Ihrer Daten stützte, und es fehlt an einer anderweitigen
                Rechtsgrundlage für die Verarbeitung.
              </li>
              <li className="mt-2">
                Ihre Daten wurden unrechtmäßig verarbeitet.
              </li>
            </ul>
            <p className="mt-2">
              Das Recht auf Löschung besteht nicht, soweit Ihre
              personenbezogenen Daten zur Geltendmachung, Ausübung oder
              Verteidigung unserer Rechtsansprüche erforderlich sind.
            </p>
            <h3 className="font-bold text-2xl pt-4">
              2.4 Recht auf Einschränkung der Verarbeitung
            </h3>
            <p>
              Sie haben das Recht von uns die Einschränkung der Verarbeitung
              Ihrer personenbezogenen Daten zu verlangen, wenn
            </p>
            <ul>
              <li className="mt-2">
                Sie die Richtigkeit der Daten bestreiten und wir daher die
                Richtigkeit überprüfen,
              </li>
              <li className="mt-2">
                die Verarbeitung unrechtmäßig ist und Sie die Löschung ablehnen
                und stattdessen die Einschränkung der Nutzung verlangen
              </li>
              <li className="mt-2">
                wir die Daten nicht länger benötigen, Sie diese jedoch zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                benötigen,
              </li>
              <li className="mt-2">
                Sie Widerspruch gegen die Verarbeitung Ihrer Daten eingelegt
                haben, und noch nicht feststeht, ob unsere berechtigten Gründe
                gegenüber Ihren Gründen überwiegen.
              </li>
            </ul>
            <h3 className="font-bold text-2xl pt-4">
              2.5 Recht auf Datenübertragbarkeit
            </h3>
            <p>
              Sie haben das Recht die Sie betreffenden personenbezogenen Daten,
              die Sie uns bereitgestellt haben, in einem strukturierten,
              gängigen und maschinenlesbaren Format zu erhalten und Sie haben
              das Recht, diese Daten einem anderen Verantwortlichen ohne
              Behinderung durch uns zu übermitteln, sofern die Verarbeitung auf
              einer Einwilligung oder einem Vertrag beruht und die Verarbeitung
              bei uns mithilfe automatisierter Verfahren erfolgt.
            </p>
            <h3 className="font-bold text-2xl pt-4">2.6 Widerrufsrecht</h3>
            <p>
              Soweit die Verarbeitung Ihrer personenbezogenen Daten auf einer
              Einwilligung beruht, haben Sie das Recht diese Einwilligung
              jederzeit zu widerrufen.
            </p>
            <h3 className="font-bold text-2xl pt-4">
              2.7 Allgemeines und Beschwerderecht
            </h3>
            <p>
              Die Ausübung Ihrer vorstehenden Rechte ist für Sie grundsätzlich
              kostenlos. Sie haben das Recht sich bei Beschwerden direkt an die
              für uns zuständige Aufsichtsbehörde, den
              Landesdatenschutzbeauftragen, zu wenden.
            </p>
            <h2 className="font-bold text-4xl pt-6">3. Datensicherheit</h2>
            <h3 className="font-bold text-2xl pt-4">3.1 Datensicherheit</h3>
            <p>
              Sämtliche Daten auf unserer Website werden durch technische und
              organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff,
              Veränderung und Verbreitung gesichert.
            </p>
            <h3 className="font-bold text-2xl pt-4">
              3.2 Sessions und Cookies
            </h3>
            <p>
              Zur Bedienung des Internetauftritts können wir Cookies oder
              serverseitige Sessions einsetzen, in denen Daten gespeichert
              werden können. Cookies sind Dateien, die von einer Webseite auf
              Ihrer Festplatte abgelegt werden, um diesen Computer beim nächsten
              Webseitenbesuch automatisch wieder zu erkennen und damit die
              Nutzung der Webseite an Sie anpassen zu können. Einige der
              verwendeten Cookies werden nach dem Ende der Browser-Sitzung
              wieder gelöscht. Dabei handelt es sich um sogenannte
              Sitzungs-Cookies. Andere Cookies verbleiben auf Ihrem Endgerät und
              ermöglichen die Wiedererkennung des Browsers bei einem späteren
              Besuch unserer Website (dauerhafte Cookies). Sie können Ihren
              Browser so einstellen, dass Sie über das Setzen von Cookies
              informiert werden und einzeln über deren Annahme entscheiden oder
              die Annahme von Cookies für bestimmte Fälle oder generell
              ausschließen. Bitte beachten Sie dabei, dass Sie möglicherweise
              einige Funktionen dieser Website nicht nutzen können, wenn die
              Cookies deaktiviert sind. Wir stellen sicher, dass keine
              personenbezogenen Daten aus Sessions oder durch Cookies übernommen
              werden und Cookies nur eingesetzt werden, sofern dies für die
              Website technisch erforderlich ist. Somit ergibt die Abwägung,
              dass keine überwiegenden Interessen Ihrerseits entgegenstehen
              (Art. 6 I S. 1 f DSGVO).
            </p>
            <h2 className="font-bold text-4xl pt-6">4. react-icons</h2>
            <p>
              Wir nutzen react-icons, eine Sammlung verschiedener Anbieter, zum
              Einbinden diverser visueller Symbole. Die Icons werden lokal über
              sogenannte .svg Dateien erzeugt und stellen keine Verbindung zu
              Servern Dritter her. Informationen über Lizenzen der eingebundenen
              Icons/Symbole finden sie unter:
              https://github.com/react-icons/react-icons
            </p>
            <p>&nbsp;</p>
            <h2 className="font-bold text-4xl pt-6">5. Google Web Fonts</h2>
            <p>
              Wir nutzen Google Web Fonts und binden diese mit einem Plugin
              (Erweiterung für diesen Zweck) lokal ein. Somit wird keine
              Verbindung zu den Google Servern hergestellt und damit auch keine
              persönlichen Daten übermittelt. Weitere Informationen zu Google
              Web Fonts finden Sie unter https://developers.google.com/fonts/faq
              und in der Datenschutzerklärung von Google:
              https://policies.google.com/privacy?hl=de.
            </p>
            <p>&nbsp;</p>
            <h2 className="font-bold text-4xl pt-6">6. Kontaktaufnahme</h2>
            <p>
              Zur Kontaktaufnahme bezüglich des Datenschutzes können Sie sich
              gern an uns unter Verwendung der nachfolgenden
              Kontaktmöglichkeiten wenden. Verantwortlicher im Sinne der DSGVO:
            </p>
            <p>
              Bastian Jordan
              <br />
              Tassilostraße 5<br />
              93093 Donaustauf
              <br />
              E-Mail: info@walhalla-software.de
              <br />
              Telefon: 015117269028
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Datenschutz;
