import React from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import DeadEnd from "../assets/deadend.png";

const NoPage = () => {
  return (
    <div className="w-full h-screen bg-[#0a0a0b]">
      <div className="w-full h-screen flex flex-col justify-center items-center">
        <Link className="fixed top-4 left-4" to="/">
          <button className="text-white group border-2 px-6 py-3 flex items-center hover:bg-pink-600 hover:border-pink-600">
            Zurück zum Start{" "}
            <span className="">
              <HiArrowNarrowLeft className="ml-3" />
            </span>
          </button>
        </Link>
        <img className="max-w-[200px]" src={DeadEnd} alt="Dead End" />
        <div className="text-pink-600 mt-2 text-4xl font-bold">DEAD END</div>
        <p className="text-pink-600">Diese Seite existiert nicht</p>
      </div>
    </div>
  );
};

export default NoPage;
